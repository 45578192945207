<template>
  <div>
    <form class="form-inline">
      <div class="form-group">
        <input class="form-control" v-model="office" placeholder="Kantoor" type="text">
      </div>
      <div class="form-group">
        <input class="form-control" v-model="product" placeholder="Product" type="text">
      </div>
      <div class="form-group">
        <input class="form-control" v-model="supplier" placeholder="Leverancier" type="text">
      </div>
      <button class="btn btn-default" type="submit" @click="setQueryParams">Zoeken</button>
    </form>
    <IAMTable
      :columns="columns"
      :endpoint="endpoint"
      :queryParams="queryParams"
      :actions="actions"
      @details="detailsAction"
      @autobook="autobookAction"
      @manubook="manuBookAction"
      @close="closeAction"
      ref="orderTable"
    ></IAMTable>
  </div>
</template>

<script>
import Vue from 'vue'
import IAMTable from '@/components/iam/table/Table'

Vue.component('order-office', {
  props: ['value', 'object'],
  template: '<span v-if="object.type === 1">{{ object.office.reference}}</span><span v-else>Verdeelsleutel</span>'
})

Vue.component('order-product', {
  props: ['value', 'object'],
  template: '<span>{{ object.product.name }}</span>'
})

Vue.component('order-supplier', {
  props: ['value', 'object'],
  template: '<span>{{ object.supplier.name }}</span>'
})

Vue.component('order-amount', {
  props: ['value', 'object'],
  template: '<span>&euro; {{ object.amount|currency }}</span>'
})

Vue.component('order-invoiced-amount', {
  props: ['value', 'object'],
  template: '<span>&euro; <span v-if="object.order">{{ object.order.invoiced_amount|currency }}</span></span>'
})

Vue.component('order-date', {
  props: ['value', 'object'],
  template: '<span>{{ object.order_date|date-day}}</span>'
})

export default {
  name: 'OrderTable',
  components: { IAMTable },
  data () {
    return {
      id: 'OrderTable',
      endpoint: '/api/budget/invoices/orders',
      columns: [
        { title: 'Kantoor', renderComponent: 'order-office' },
        { title: 'Datum', renderComponent: 'order-date' },
        { title: 'Product', renderComponent: 'order-product' },
        { title: 'Leverancier', renderComponent: 'order-supplier' },
        { title: 'Bedrag', renderComponent: 'order-amount' },
        { title: 'Gefactureerd', renderComponent: 'order-invoiced-amount' }
      ],
      actions: [
        {
          key: 'details',
          type: 'info',
          icon: 'search',
          title: 'Details'
        },
        {
          key: 'autobook',
          type: 'primary',
          icon: 'euro',
          title: 'Automatisch Inboeken'
        },
        {
          key: 'manubook',
          type: 'primary',
          icon: 'pencil',
          title: 'Manueel Inboeken'
        },
        {
          key: 'close',
          type: 'warning',
          icon: 'lock',
          title: 'Bestelling afsluiten'
        }
      ],
      office: null,
      product: null,
      supplier: null,
      queryParams: {}
    }
  },
  methods: {
    setQueryParams () {
      const params = {
        office: this.office,
        product: this.product,
        supplier: this.supplier
      }
      this.queryParams = Object
        .entries(params)
        .reduce((accum, [key, value]) => {
          if (value !== null) {
            accum[key] = value
            return accum
          } else {
            return accum
          }
        }, {})
    },
    detailsAction (order) {
      this.$emit('details', order)
    },
    autobookAction (order) {
      this.$emit('autobook', order)
    },
    manuBookAction (order) {
      this.$emit('manubook', order)
    },
    closeAction (order) {
      this.$emit('close', order)
    },
    refresh () {
      this.$refs.orderTable.refresh()
    }
  }
}
</script>

<style scoped>

</style>
