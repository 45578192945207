<template>
  <Page>
    <span slot="title">Facturen inboeken</span>
    <div slot="actionbar">
      <div class="btn-group">
      </div>
    </div>
    <div class="row">
      <div class="col-sm-8">
        <Panel :is-collapsible="false" title="Bestellingen">
          <span slot="toolbar-buttons"></span>
          <OrderTable @autobook="autoBooking"
                      @close="closeOrder"
                      @details="showOrderDetails"
                      @manubook="manualBooking"
                      ref="orderTable"
          ></OrderTable>
        </Panel>
      </div>
      <div class="col-sm-4">
        <Panel :is-collapsible="false" title="Factuur">
          <span slot="toolbar-buttons"></span>
          <div>

            <div class="input-group">
              <input class="form-control" placeholder="zoeken op factuur nr" type="text" v-model="invoiceQuery"
                     @keyup.enter="searchInvoice">
              <span class="input-group-btn">
                <button @click="loadPreviousInvoice" class="btn btn-default">Vorige</button>
                <button @click="loadNextInvoice" class="btn btn-default">Volgende</button>
              </span>
            </div>

            <div v-if="invoice">
              <dl class="dl-horizontal">
                <dt>Nr</dt>
                <dd v-text="invoice.number" />
                <dt>Datum</dt>
                <dd>{{ invoice.date|date-day }}</dd>
                <dt>Leverancier</dt>
                <dd><span v-if="invoice.supplier">{{ invoice.supplier.name }}</span></dd>
                <dt>Bedrag</dt>
                <dd>&euro; {{ invoice.amount|currency }}</dd>
                <dt>Milieubijdrag</dt>
                <dd>&euro; {{ invoice.environment_contribution|currency }}</dd>
                <dt>Saldo</dt>
                <dd>&euro; {{ invoice.balance|currency }}</dd>
                <dt>Opmerkingen</dt>
                <dd>{{ invoice.description }}</dd>
              </dl>
            </div>
            <div v-else>
              <p>Er zijn geen in te boeken facturen</p>
            </div>
          </div>
        </Panel>
      </div>
    </div>
    <OrderDetailModal :order="selectedOrder" ref="orderDetailModal"></OrderDetailModal>

  </Page>
</template>

<script>
import $ from 'jquery'
import Page from '@/components/iam/Page'
import Panel from '@/components/iam/Panel'
import OrderTable from '@/components/budget/OrderTable'
import OrderDetailModal from '@/components/budget/OrderDetailModal'
import { questionModal, startLoadingModal, stopLoadingModal, errorModal } from '@/modalMessages'
import swal from 'sweetalert2'

export default {
  name: 'InvoiceBooking',
  components: { OrderDetailModal, OrderTable, Panel, Page },
  data () {
    return {
      selectedOrder: null,
      invoice: null,
      nextInvoiceId: null,
      invoiceQuery: ''
    }
  },
  created () {
    this.getInvoiceToBook()
  },
  methods: {
    searchInvoice () {
      if (this.invoiceQuery) {
        const url = `/api/budget/invoices/to-book?query=${this.invoiceQuery}`
        $.ajax({
          url
        }).done((invoiceData) => {
          this.processData(invoiceData)
          this.getInvoiceToBook()
        }).fail((error) => {
          console.error(error)
        })
      } else {
        this.getInvoiceToBook()
      }
    },
    showOrderDetails (order) {
      this.selectedOrder = order
      this.$refs.orderDetailModal.show()
    },
    /**
     * Performs automatic booking on an order. This books the entire Invoice amount to the selected order.
     * @param order The order on which to perform an automatic booking
     */
    autoBooking (order) {
      let url = `/api/budget/invoices/${this.invoice.id}`
      if (order.order) {
        url += `/book-to-order/${order.id}`
      } else {
        url += `/book-to-distribution/${order.id}`
      }

      $.ajax({
        url: url,
        type: 'POST'
      }).done(data => {
        if (data.invoice_closed) {
          this.loadNextInvoice()
        }
        this.$refs.orderTable.refresh()
      }).fail(error => {
        console.error(error)
      })
    },
    /**
     * Ask the user what amount he wants to manually book for a specified order
     * @param order The order on which to perform a manual booking
     */
    async manualBooking (order) {
      const result = await swal({
        title: 'Welk bedrag wens je in te boeken op deze bestelling?',
        input: 'text'
      })

      if (!result.value) return

      const amount = result.value.replace(',', '.')
      let url = `/api/budget/invoices/${this.invoice.id}`
      if (order.order) {
        url += `/book-to-order/${order.id}`
      } else {
        url += `/book-to-distribution/${order.id}`
      }

      $.ajax({ url: url, type: 'POST', data: { amount: amount } }).done((data) => {
        if (data.invoice_closed) {
          this.loadNextInvoice()
        } else {
          this.getInvoiceToBook()
        }
        this.$refs.orderTable.refresh()
      }).fail(error => {
        console.error(error)
        errorModal('Kan bedrag niet inboeken voor bestelling, gelieve opnieuw te proberen')
      })
    },
    /**
     * Close the selected order and reload the order table
     * @param order The order to close
     */
    async closeOrder (order) {
      const result = await questionModal('Weet je zeker dat je deze bestelling wil afsluiten?')
      if (!result.value) return

      startLoadingModal('Bezig met het afsluiten van deze bestelling...')
      let url = '/api/budget/'
      if (order.order) {
        url += `orders/${order.id}`
      } else {
        url += `distribution-orders/${order.id}`
      }

      $.ajax({
        url: url,
        type: 'PATCH',
        data: {
          closed: true
        }
      }).done(() => {
        this.$refs.orderTable.refresh()
        stopLoadingModal()
      }).fail(error => {
        console.error(error)
        errorModal('Kan bestelling niet afsluiten, gelieve opnieuw te proberen')
      })
    },
    /**
     * Get the information about what invoice is available to book
     * This call returns the Invoice object that we can currently book, it also returns the ID of the next and
     * previous invoice.
     */
    getInvoiceToBook () {
      let url = '/api/budget/invoices/to-book'
      if (this.invoice) {
        url += `?current=${this.invoice.id}`
      }

      $.ajax({
        url: url,
        type: 'GET'
      }).done((invoiceData) => {
        this.processData(invoiceData)
      }).fail((error) => {
        console.error(error)
      })
    },
    /**
     * Load the next Invoice to book and store information about the next and previous invoices
     */
    loadNextInvoice () {
      if (this.nextInvoiceId) {
        const url = `/api/budget/invoices/${this.nextInvoiceId}`

        $.ajax({
          url
        }).done((invoiceData) => {
          this.processData(invoiceData)
          this.getInvoiceToBook()
        }).fail((error) => {
          console.error(error)
        })
      }
    },
    /**
     * Load the previous Invoice to book and store information about the next and previous invoices
     */
    loadPreviousInvoice () {
      if (this.previousInvoiceId) {
        const url = `/api/budget/invoices/${this.previousInvoiceId}`

        $.ajax({
          url
        }).done((invoiceData) => {
          this.processData(invoiceData)
          this.getInvoiceToBook()
        }).fail((error) => {
          console.error(error)
        })
      }
    },
    /**
     * Process Invoice data and store the it in our local state
     * @param invoiceData
     */
    processData (invoiceData) {
      if (Object.keys(invoiceData).length !== 0) {
        this.invoice = invoiceData
        this.nextInvoiceId = invoiceData.next_id
        this.previousInvoiceId = invoiceData.previous_id
      } else {
        this.invoice = null
      }
    }
  }
}
</script>
